<template>
  <v-container class="mt-5 main-content" >
    <div class="Title" >
      <h1 class="text-h5">  無料コンテンツ <v-btn icon @click="slide=true;"><v-icon>{{icons.informationOutline}}</v-icon></v-btn></h1>
      <FreeContentsTabel />
    </div>

    
 

  </v-container>
  
</template>

<script>

  const FreeContentsTabel = () => import('@/components/FreeContentsTable.vue');
  import { mdiInformationOutline } from '@mdi/js'
  export default {
    name: 'FreeContents',
    components:{FreeContentsTabel},

    data: () => ({
      icons:{
        "informationOutline":mdiInformationOutline
      },
      contentItem:null,
      slide:false,
      object:{
        type:"object",
        title:"3Dモデル",
        id:"objectId"
      },
      image:{
        type:"image",
        title:"画像",
        id:"imageId"  
      },
      pdf:{
        type:"pdf",
        title:"PDF",
        id:"pdfId"  
      },
      sound:{
        type:"sound",
        title:"音声",
        id:"soundId"  
      },
      movie:{
        type:"movie",
        title:"動画",
        id:"movieId"  
      },
      url:{
        type:"url",
        title:"URL",
        id:"urlId"  
      }
    }),


      
      
    
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 400;
    border-bottom: 1px solid gray;
  }

    #play-sound{
    position: absolute;
    display: inline;
    margin:0;
    z-index:1001;
    left: 0;
    bottom:0;
    width:100%;
    height:70px;
    background: white;
    box-shadow:0 0.5px 3px 0  #ccc inset;
  }

  #play-sound-left{

    display: flex;
    padding-top:10px;
    width: 10%;
    height: 100%;
    margin: 0 auto 0 40px;
  }

  
  #play-sound-left > p  {
    margin: auto 0 auto 0;
    padding:2px  0 0 10px;
  }



  #play-sound-center{
    position: relative;
    padding-top:14px;
    width: 45%;
    height: 50px;
    margin: 0 auto 0 auto;
  }

  #play-sound-right{
    display: flex;
    padding: 10px 10px 0 0;
    width: 15%;
    height: 100%;
  
  }

  #play-sound-right > div > * {
    opacity: 0.8;
    padding:0 10px 0 10px;
  }
  
  #play-sound-right:nth-child(0){
    margin:auto 0 auto auto;
  }

  audio::-webkit-media-controls-play-button,
     audio::-webkit-media-controls-panel {
     background-color:  white;
     color: rgb(142, 142, 142); 
     opacity: 0.8;
  }

  
.v-application{
  font-family: 'Noto Sans JP', sans-serif;
}

.application {
  font-family: 'Noto Sans JP', sans-serif;
}



</style>
